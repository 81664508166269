import { mapIndiceToDocGroup } from 'utils/commonTools';
import { translateKeyword, translation, translateMinistry } from 'utils/translate';

const config = {
  apiUrl: '/api/',

  /* Site settings */
  site: {
    translatedName: {fi:'LAKITUTKA',se:"LAGRADARN"},
    name: 'LAKITUTKA',
    slogan: {
      fi:'Lainvalmistelu läpivalaistuna.',
      se:'Transparent lagberedning',
    }
  },

  defaultDocGroup: 'hallituksen_esitykset',

  activeIndices: [
    'lausuntokierros',
    'lakialoite',
    'kansalaisaloite',
    'hallituksen_esitykset',
    'valiokunta_asiakirjat',
    'he_asiantuntijalausunnot',
    'poytakirja',
    // 'eduskunnan_vastaukset', NO INDEX!
  ],

  /*
   * DOCUMENT GROUP SETTINGS
   * ! group's main key is used in API-call urls
   * name: Readable name of the document group
   * searchPrompt: placeholder for the text field in search form
   * facetFields: fields to use in aggregating and filtering search results (same as in backend)
   * infoFields: fields to display in info-area in search results and document page (1-2)
   * tagFields: for tag-type list-fields, like 'asiasanat' (if any)
   * multiSelectFields: fields to include in preFilters
   * searchable: boolean for if group is added to seach page tabs
   */
  docGroups: {
    // all: {
    //   name: 'Kaikki asiakirjat',
    //   singular: 'Asiakirja',
    //   searchPrompt: 'Hae kaikista asiakirjoista',
    //   facetFields: ['year', '_index'],
    //   infoFields: [
    //     'laadittu',
    //     'hallitus_hk',
    //     'valiokunta',
    //     'aloitus_paiva',
    //   ],
    //   multiSelectFields: [],
    //   radioSelectFields: [],
    //   searchable: true,
    // },
    lausuntokierros: {
      name: 'Valmisteluasiakirjat',
      singular: 'Lainsäädäntöhanke',
      timelineName: 'Hankkeen aloitus',
      facetFields: [
        'asiakirjat.tyyppi',
        'year',
        'tila',
        'asettaja',
        'asiasanat',
        'laatija'
      ],
      infoFields: ['aloitus_paiva', 'hallitus_hk', 'laatimispaiva', 'laatija'],
      tagFields: {
        fi: ['asiasanat'],
        se: ['asiasanat_sv'],
      },
      multiSelectFields: ['asettaja', 'tila'],
      radioSelectFields: [
        {
          name: 'asetukset',
          id: 'asetukset',
          fields: ['without', 'strictly'],
        },
      ],
      searchable: true,
    },
    lausuntokierros_asiakirjat: {
      name: 'Lausuntokierroksen asiakirjat',
      singular: 'Lausunto',
      infoFields: ['asettaja', 'laatija'],
      searchable: false,
    },
    lausuntokierros_lausunnot: {
      name: 'Lausuntokierroksen lausunnot',
      singular: 'Lausunto',
      infoFields: ['asettaja', 'laatija'],
      searchable: false,
    },
    lausuntokierros_muut: {
      name: 'Lausuntokierroksen muut asiakirjat',
      singular: 'Lausuntokierroksen asiakirja',
      infoFields: ['asettaja', 'laatija'],
      searchable: false,
    },
    lakialoite: {
      name: 'Lakialoitteet',
      singular: 'Lakialoite',
      facetFields: [
        '_index',
        'year',
        'asian_tila',
        'hallitus_hk',
        'asiasanat',
      ],
      infoFields: ['laadittu', 'hallitus_hk'],
      tagFields: {
        fi: ['asiasanat'],
        se: ['asiasanat_sv'],
      },
      multiSelectFields: ['allekirjoittaja', 'hallitus_hk'],
      radioSelectFields: [],
      searchable: true,
      // hideFromProduction: true,
    },
    kansalaisaloite: {
      name: 'Kansalaisaloitteet',
      singular: 'Kansalaisaloite',
      facetFields: [
        '_index',
        'year',
        'asian_tila',
        'hallitus_hk',
        'asiasanat',
      ],
      infoFields: ['laadittu', 'hallitus_hk'],
      tagFields: {
        fi: ['asiasanat'],
        se: ['asiasanat_sv'],
      },
      multiSelectFields: ['allekirjoittaja', 'hallitus_hk'],
      radioSelectFields: [],
      searchable: false,
      // hideFromProduction: true,
    },
    hallituksen_esitykset: {
      name: 'Hallituksen esitykset',
      singular: 'Hallituksen esitys',
      facetFields: [
        'year',
        'asian_tila',
        'ministerio',
        'hallitus_hk',
        'asiasanat',
        'asiasanat_sv',
      ],
      infoFields: ['laadittu', 'hallitus_hk'],
      secondaryInfoField: ['ministerio'],
      tagFields: {
        fi: ['asiasanat'],
        se: ['asiasanat_sv'],
      },
      multiSelectFields: ['ministerio', 'hallitus_hk'],
      radioSelectFields: [
        {
          name: 'talousarviot',
          id: 'talousarviot',
          fields: ['without', 'strictly'],
        },
        {
          name: 'kirjelmat',
          id: 'eduskunnan_kirjelma',
          fields: ['without', 'strictly'],
        },
      ],
      searchable: true,
      searchView: {
        handlingState: 'asian_tila',
      },
    },
    he_asiantuntijalausunnot: {
      name: 'Asiantuntijalausunnot',
      singular: 'Asiantuntijalausunto',
      facetFields: ['year', 'type', 'valiokunta'],
      infoFields: ['laadittu', 'valiokunta'],
      multiSelectFields: ['valiokunta', 'type'],
      searchable: false,
    },
    valiokunta_asiakirjat: {
      name: 'Valiokunta-asiakirjat',
      singular: 'Valiokunta-asiakirja',
      facetFields: ['year', 'type', 'valiokunta', 'hallitus_hk'],
      tagFields: {
        fi: ['asiasanat'],
        se: ['asiasanat_sv'],
      },
      infoFields: ['laadittu', 'hallitus_hk'],
      multiSelectFields: ['valiokunta', 'type', 'hallitus_hk'],
      radioSelectFields: [],
      searchable: true,
    },
    poytakirja: {
      name: 'Eduskuntakeskustelut',
      singular: 'Eduskuntakeskustelu',
      facetFields: ['year', 'asiasanat', 'hallitus_hk'],
      infoFields: [],
      multiSelectFields: ['hallitus_hk'],
      tagFields: {
        fi: ['asiasanat'],
        se: ['asiasanat_sv'],
      },
      radioSelectFields: [],
      searchable: true,
    },
    eduskunnan_vastaukset: {
      name: 'Eduskunnan vastaukset',
      singular: 'Eduskunnan vastaus',
      infoFields: ['toimija'],
      tagFields: {
        fi: ['asiasanat'],
        se: ['asiasanat_sv'],
      },
      searchable: false,
    },
    laws: {
      name: 'Lakipäätökset',
      singular: 'Lakipäätös',
      infoFields: [],
      searchable: false,
    },
    all: {
      name: 'Kaikki asiakirjat',
      singular: 'Asiakirja',
      // facetFields: ['year', '_index'],
      facetFields: [
        'year',
        'asian_tila',
        'ministerio',
        'hallitus_hk',
        'asiasanat',
        'asiasanat_sv',
        '_index',
      ],
      infoFields: [
        'laadittu',
        'hallitus_hk',
        'valiokunta',
        'aloitus_paiva',
      ],
      tagFields: {
        fi: ['asiasanat'],
        se: ['asiasanat_sv'],
      },
      multiSelectFields: [],
      radioSelectFields: [],
      searchable: true,
    },
  },
};

config.docGroups.all.formatTitle = function(
  source,
  index,
  title,
  lang
) {
  const found = mapIndiceToDocGroup(index)
  if (found) {
    const docGroup = found.replace(
      '-',
      '_',
    );
    if ('formatTitle' in config.docGroups[docGroup]) {
      return `${translateKeyword(index, lang)}: ${config.docGroups[
        docGroup
      ].formatTitle(source, null, title, lang)}`;
    }
  }

  return `${translateKeyword(index, lang)}: ${title}`
}

config.docGroups.all.formatSecondaryInfoField = function(
  source,
  index,
  lang
) {
  const found = mapIndiceToDocGroup(index)
  if (found) {
    const docGroup = found.replace(
      '-',
      '_',
    );
    if (
      'formatSecondaryInfoField' in config.docGroups[docGroup]
    ) {
      return config.docGroups[
        docGroup
      ].formatSecondaryInfoField(source, null, lang);
    } else {
      return null;
    }
  }

  return null;
};

config.docGroups.valiokunta_asiakirjat.formatSecondaryInfoField =
  function(source, index, lang) {
    if (
      source.type === 'Asiantuntijalausunto' &&
      source.liittyy
    ) {
      return `<span class="info-field--key">${translation("liittyy", lang)}:</span> <span class="info-field--value">${source.liittyy}</span>`;
    }
    if (source.hanke) {
      return `<span class="info-field--key">${translation("hanke", lang)}:</span> <span class="info-field--value">${source.hanke}</span>`;
    }
    return null;
  };

config.docGroups.poytakirja.formatSecondaryInfoField =
  function(source, index, lang) {
    if (source.hanke) {
      return `<span class="info-field--key">${translation("hanke", lang)}:</span> <span class="info-field--value">${source.hanke}</span>`;
    }
    return null;
  };

config.docGroups.lakialoite.formatSecondaryInfoField =
  function(source, index, lang) {
    if (
      source.allekirjoittaja &&
      ((source.tunnus &&
        source.tunnus.startsWith('LA')) || (source.tunnus_sv && source.tunnus_sv.startsWith('LM')))

    ) {
      return `<span class="info-field--key">${translation("first_signee", lang)}:</span> <span class="info-field--value">${source.allekirjoittaja}</span>`;
    }
    return null;
  };

config.docGroups.hallituksen_esitykset.formatSecondaryInfoField =
  function(source, index, lang) {
    if (lang === 'se')
    {
      if (source.ministerio) {
        return `<span class="info-field--key">${translation("valmisteltu", lang)}:</span> <span class="info-field--value">${translateMinistry(source.ministerio, lang)}</span>`;
      }
    }
    else
    {
      if (source.ministerio) {
        return `<span class="info-field--key">${translation("valmisteltu", lang)}:</span> <span class="info-field--value">${translateMinistry(source.ministerio, lang)}</span>`;
      }
    }
    return null;
  };

config.docGroups.lausuntokierros.formatSecondaryInfoField =
  function(source, index, lang) {
    if (source.asettaja) {
      return `<span class="info-field--key">${translation("vastuussa", lang)}:</span> <span class="info-field--value">${translateKeyword(source.asettaja,lang)}</span>`;
    }
    return null;
  };

config.docGroups.kansalaisaloite.formatTitle = function(
  source,
  index,
  title,
  lang
) {
  if (source.tunnus && (source.tunnus.startsWith('KAA'))) {
    return `${translation("doc_groups", "kansalaisaloite", "singular", lang)}: ${title}`
  }
  if (source.tunnus_sv && (source.tunnus_sv.startsWith('MI'))) {
    return `${translation("doc_groups", "kansalaisaloite", "singular", lang)}: ${title}`
  }

  return title
};

config.docGroups.lakialoite.formatTitle = function(
  source,
  index,
  title,
  lang
) {
  if (!title) {
    return translation("missing_title", lang)
  }

  if (source.tunnus && (source.tunnus.startsWith('KAA'))) {
    return `${translation("doc_groups", "kansalaisaloite", "singular", lang)}: ${title}`
  }
  if (source.tunnus_sv && (source.tunnus_sv.startsWith('MI'))) {
    return `${translation("doc_groups", "kansalaisaloite", "singular", lang)}: ${title}`
  }

  const lowerCaseTitle =
    title.charAt(0).toLowerCase() +
    title.slice(1);

  return `${translation("edustajan", lang)} ${lowerCaseTitle}`;
};

config.docGroups.poytakirja.formatTitle = function(
  source,
  index,
  title,
  lang
) {
  if (!title) {
    return translation("missing_title", lang)
  }

  const result = title.match(
    '[0-9]{1,2}([-/.])[0-9]{1,2}[-/.][0-9]{4}',
  );

  if (result) {
    return `${translation("taysistunto", lang)} ${result}`;
  }
  return title
};

config.docGroups.valiokunta_asiakirjat.formatTitle =
  function(source, index, title, lang) {
    if (!title) {
      return translation("missing_title", lang)
    }

    if (source.type === 'Asiantuntijalausunto') {
      return `${translation("doc_groups", "he_asiantuntijalausunto", "singular", lang)} - ${title.split(/[0-9]{4}/).pop().replace('Asiantuntijalausunto', '').trim()}`;
    }

    if (source.year < 2015) {
      return title;
    }
    if (!source.valiokunta) {
      return title;
    }

    // TODO: translate rest of the formatting to SE
    if (lang === "se") {
      return title
    }

    const valiokunta = source.valiokunta
      .replace(/..$/, 'nan')
      .toLowerCase();
    const vkType = source.type.replace('Valiokunnan ', '');
    const formattedTitle = title
      .replace(
        'Hallituksen esitys',
        'hallituksen esityksestä',
      )
      .replace('Lakialoite', 'lakialoitteesta')
      .replace('Kansalaisaloite', 'kansalaisaloitteesta');

    return `Eduskunnan ${valiokunta} ${vkType} ${formattedTitle}`;
  };

export default config;
