import React, { useState, useEffect } from 'react';
import { Paper, Grid, Box } from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import TocIcon from '@material-ui/icons/Toc';
import './TableOfContent.css';
import { translation } from 'utils/translate';
import { useSelector } from 'react-redux';

function isUpperCase(str) {
  return str.toUpperCase() === str;
}

const TableOfContent = ({ toc }) => {
  const [tocShow, setTocShow] = useState(true);
  const [minimized, setMinimized] = useState(false);
  const lang = useSelector((store) => store.lang);
  const [tocTop, setTocTop] = useState('0px');
  const [isFixed, setIsFixed] = useState(false);

  const minimizeToc = () => {
    setTocShow(false);
    setMinimized(true);
  };

  const maximizeToc = () => {
    setTocShow(true);
    setMinimized(false);
  };

  const updateTocPosition = () => {
    const mainContent = document.getElementById('document');
    if (mainContent) {
      const mainRect = mainContent.getBoundingClientRect();
      setTocTop(`${mainRect.top + window.scrollY}px`);

      const scrollPosition = window.scrollY;
      if (scrollPosition >= mainRect.top + window.scrollY) {
        setIsFixed(true);
        setTocTop('0px');
      } else {
        setIsFixed(false);
        setTocTop(`${mainRect.top + window.scrollY}px`);
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      updateTocPosition();
    };

    // Initialize TOC position
    updateTocPosition();
    window.addEventListener('scroll', handleScroll);


    window.addEventListener('resize', updateTocPosition); // Update on resize

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', updateTocPosition);
    };
  }, []); // Empty dependency array to run effect once

  return (
    <Paper
      elevation={0}
      style={{ top: tocTop, position: isFixed ? 'fixed' : 'absolute' }}
      className={minimized ? 'anchored-minimized-toc' : 'anchored-toc'}
    >
      <Grid
        container
        className="toc-title"
        justifyContent="flex-end"
      >
        {tocShow ? (
          <Grid item xs={10}>
            <h3> {translation("document", "toc", lang)} </h3>
          </Grid>
        ) : (
          <Grid item xs={6}>
            <TocIcon className="toc-icon" />
          </Grid>
        )}
        <Grid item xs={tocShow ? 2 : 6}>
          <Box display="flex" justifyContent="flex-end">
            {tocShow ? (
              <ArrowLeftIcon
                fontSize="large"
                className={`min-max-toc ${!tocShow ? 'hidden' : null}`}
                onClick={minimizeToc}
              />
            ) : (
              <ArrowRightIcon
                fontSize="large"
                className="min-max-toc"
                onClick={maximizeToc}
              />
            )}
          </Box>
        </Grid>
      </Grid>

      <div className="toc">
        {tocShow &&
          toc.map((sub) =>
            sub.map((el) => (
              <a
                href={`#${el[1]}`}
                onClick={(e) => {
                  e.preventDefault();
                  document.querySelector(`#${el[1]}`).scrollIntoView({
                    behavior: "smooth"
                  });
                }}
                className={`toc-link
                  ${
                    isUpperCase(el[0])
                      ? 'toc-root'
                      : el[0].startsWith('Laki')
                      ? 'toc-root toc-laki'
                      : el[0].includes('.') ||
                        el[0].includes('§')
                      ? 'toc-sub-sub'
                      : !Number.isNaN(Number(el[0][0]))
                      ? 'toc-sub'
                      : null
                  }
                `}
              >
                {el[0]}
              </a>
            )),
          )}
      </div>
    </Paper>
  );
};

export default TableOfContent;