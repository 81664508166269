import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Helmet } from 'react-helmet';
import {
  Paper,
  Grid,
  CircularProgress,
  Fab,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Section from 'components/Section';
import Tags from 'components/Tags';
import DocInfo from 'components/DocInfo';
import Identifier from 'components/Identifier';
import DocumentSide from 'components/DocumentSide';
import TableOfContent from 'components/TableOfContent';
import TimeLine from 'components/TimeLine';
import InfoBox from 'components/InfoBox';
import Siblings from 'components/Siblings';
import apiService from 'services/apiService';
import {
  capitalizeFirstLetter,
  cleanTitle,
  getDocumentUrl,
  getLausuntopalveluUrl,
  scrollToTop,
  formatDate,
} from 'utils/commonTools';
import { translateKeyword, translation } from 'utils/translate';
import {
  reportError,
  clearError,
} from 'actions/errorActions';
import texts from 'texts';
import config from 'config';
import './Document.css';

import { Link, useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { changeLanguage } from 'actions/langActions';

const wordChars =
  'a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ0-9';

const MEDIA_TYPES = [
  'image/jpeg',
  'image/tiff',
  'video/mp4',
];

// Adds <em> tags around words found in termCounts
// termCount format is [{ word: 'nn', count: 1 }, {}...]
const highlightContent = (source, termCounts) => {
  let output = source;
  termCounts.forEach(({ word }) => {
    const reg = new RegExp(
      `(?![^${wordChars}]|^)${word}(?=[^${wordChars}]|$)`,
      'gim',
    );
    output = output.replace(
      reg,
      (regMatch) =>
        `<em class="highlight">${regMatch}</em>`,
    );
  });
  return output;
};

const useStyles = makeStyles((theme) => ({
  reverse: {
    // [theme.breakpoints.up('md')]: {
    //   flexDirection: 'row-reverse',
    // },
    // [theme.breakpoints.up('lg')]: {
    //   flexDirection: 'row',
    // },
    justifyContent: "end",
  },
  // not working ( needs mui v5!)
  reverseColumn: {
    display:"flex",
    flexDirection:"column",
    // order: 1,
    // [theme.breakpoints.up('md')]: {
    //   order: 3,
    // },

  },
  buttonForFinnishDoc:{
    textAlign:'left',
    color: '#2564ec',
    textDecorationStyle: 'italic',
    fontStyle: 'italic',
    paddingLeft: '0rem',
  }
}));

// MAIN COMPONENT
const Document = ({ match, location }) => {
  const docGroup = match.params.doc_group.replace('-', '_');
  const docID = match.params.doc_id;
  const dispatch = useDispatch();

  const [status, setStatus] = useState('initial');
  const [docData, setDocData] = useState({});
  const [sideData, setSideData] = useState({});
  const [timelineData, setTimelineData] = useState([]);
  const [siblings, setSiblings] = useState([]);
  const [showFab, setShowFab] = useState(false);

  const classes = useStyles();

  const lang = useSelector((store) => store.lang)
  const history = useHistory()

  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 800) {
        setShowFab(true);
      } else {
        setShowFab(false);
      }
    });
  }, [showFab]);


  useEffect(() => {
    const fetchDoc = async () => {
      setStatus((prev) =>
        prev === 'initial' ? 'loading' : 'loadingMore',
      );
      setDocData({});
      try {
        const result = await apiService.fetchData(
          `docs/${docGroup}/${docID}${location.search}`,
        );

        setDocData(result.doc);

        // check if there is timeline data in memory
        if (timelineData.length === 0) {
          const timeline = await apiService.fetchData(
            `timeline/${docGroup}/${docID}`,
          );
          if (timelineData) {
            setTimelineData(timeline);
          }
        }

        setSideData({
          wc: result.wc
            ? result.wc.map(({ word, count }) => ({
              word: capitalizeFirstLetter(word),
              count,
            }))
            : [],
          termCounts:
            result.terms && (result.terms.content || result.terms.content_sv)
              ? result.terms.content || result.terms.content_sv
              : [],
          innerHits: result.inner_hits || [],
          meta: result.project_meta,
        });
        setStatus('ready');
        dispatch(clearError());
      } catch (err) {
        dispatch(reportError(err));
      }
    };
    fetchDoc();
    // TODO: refactor this effect hook to enable the use of exhaustive deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, docGroup, docID, lang]);

  // Add sibling (same document type of the current document) data when timeline is loaded or docID changes
  useEffect(() => {
    if (
      [
        'lausuntokierros',
        'lausuntokierros_muut',
        'lausuntokierros_lausunnot',
        'valiokunta_asiakirjat',
        'he_asiantuntijalausunnot',
        'poytakirja',
      ].includes(docGroup)
    ) {
      setSiblings(
        timelineData
          .flatMap((item) =>
            docGroup === 'lausuntokierros'
              ? [
                ...item.lausuntokierros_lausunnot,
                ...item.lausuntokierros_muut,
                ...item.lausuntopalvelu_lausunnot,
              ]
              : docGroup === 'lausuntokierros_lausunnot'
                ? [
                  ...item.lausuntokierros_lausunnot,
                  ...item.lausuntopalvelu_lausunnot,
                ]
                : item[docGroup],
          )
          .filter((item) => Object.keys(item).length !== 0)
          .map((item) => ({
            id: item.doc_id || item.id,
            date:
              item.date ||
              item.asettamis_paiva ||
              item.laadittu ||
              item.vahvistettu ||
              item.laatimispaiva ||
              item.published_on,
            closingDate: item.closing_date,
            title: cleanTitle(
              docGroup === 'lausuntokierros_muut' ||
                docGroup === 'lausuntokierros'
                ? item.closing_date || item.deadline
                  ? `Lausuntopalvelun lausunnot (${item.lausunnot})`
                  : [item.laatija, translateKeyword(item.tyyppi, lang)]
                    .filter((i) => i)
                    .join(', ')
                : item.laatija || item.title,
            ),
            link: item.closing_date || item.deadline
              ? getLausuntopalveluUrl(item.id)
              : getDocumentUrl(
                docGroup,
                item.doc_id || item.id,
              ),
          }))
          // '99's for sorting null dates to end
          .sort((a, b) =>
            (a.date || '99').localeCompare(b.date || '99'),
          )
          // sort the sibling with time range (closingDate) to first in the list
          .reduce((acc, element) => {
            if (element.closingDate) {
              return [element, ...acc];
            }
            return [...acc, element];
          }, []),
      );
    } else {
      setSiblings([]);
    }
  }, [docGroup, timelineData, lang]);

  // Format text to html
  function formatContent(content) {
    return {
      __html: !sideData.termCounts
        ? content
        : highlightContent(content, sideData.termCounts),
    };
  }

  // InfoBox in case content is not available
  function renderNoContent() {
    return (
      <InfoBox>
        {docGroup.startsWith('lausuntokierros') ? (
          <i>{texts.info.lausunnot_no_content[lang]}</i>
        ) : (
          <i>{translation("document", "no_content", lang)}</i>
        )}
      </InfoBox>
    );
  }

  function goToFinnishVersion() {
    const url = new URL(window.location);
    url.searchParams.set("lang", "fi");
    console.log("url: ", url)
    history.push(url.search);
    dispatch(changeLanguage("fi"))
  }

  function renderNoContentInSwedish(){
    return (
      <InfoBox>
        {docGroup.startsWith('lausuntokierros') ? (
          <i>{texts.info.lausunnot_no_content[lang]}</i>
        ) : (
          <>
            <i>{translation("document", "no_content_in_swedish", lang)}</i>
            <div>
              <Button className={classes.buttonForFinnishDoc} onClick={goToFinnishVersion} >
              Visa dokumentet på finska
              </Button>
            </div>
          </>
        )}
      </InfoBox>
    );
  }

  if (status === 'loading') {
    return (
      <Section className="section--document">
        <Grid container spacing={4}>
          {/* Show throbber here when loading initial document */}
          <div className="progress-wrapper fade-in">
            <CircularProgress size={68} />
          </div>
        </Grid>
      </Section>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {docData._source
            ? `${
            docData._source.tunnus ||
            docData._source.tunnus_sv ||
            docData._source.doc_id ||
            docData._source.title
            } | ${config.site.name}`
            : config.site.name}
        </title>
      </Helmet>

      <Section className="section--timeline">
        {timelineData.map((timeline) => (
          <TimeLine
            key={
              timeline.hallituksen_esitykset[0]
                ? timeline.hallituksen_esitykset[0].doc_id
                : 'he_missing'
            }
            data={timeline}
            activeDoc={docID}
            docGroup={docGroup}
            docData={docData}
          />
        ))}
      </Section>

      <Section className="section--document">
        <Grid
          container
          spacing={4}
          direction='row'
          className={classes.reverse}
        >
          {status === 'ready' && docData._source.toc && (
            <Grid item xs={12} sm={12} md={3} lg={3} >
              <TableOfContent toc={docData?._source?.toc} />
            </Grid>
          )}
          {status === 'loadingMore' ? (
            /* Show throbber here when loading new document */
            <div className="progress-wrapper fade-in">
              <CircularProgress size={68} />
            </div>
          ) : (
            <>
              <Grid item xs={12} sm={12} md={docData?._source?.toc? 9: 12} lg={docData?._source?.toc? 6: 9}>
                {!!docData._source && (
                  <Paper elevation={2}>
                    <main
                      id="document"
                      className="document"
                    >
                      <header>
                        {!!(
                          docData._source.tunnus ||
                          docData._source.tunnus_sv ||
                          docData._source.tyyppi
                        ) && (
                            <Identifier>
                              {docData._source.tunnus || docData._source.tunnus_sv ||
                                translation(
                                  docData._source.tyyppi, lang
                                ).toUpperCase()}
                            </Identifier>
                          )}
                        <DocInfo
                          fields={config.docGroups[
                            docGroup
                          ].infoFields.map((field) => [
                            field,
                            docData._source[field],
                          ])}
                        />
                        <h1 className='title'>{docData._source.title || docData._source.title_sv}</h1>
                        {/* Render fields listed in docGroup's tagFields in config */}
                        {config.docGroups[docGroup]
                          .tagFields &&
                          config.docGroups[
                            docGroup
                          ].tagFields[lang].map(
                            (field) =>
                              docData._source[field] && (
                                <Tags
                                  key={field}
                                  title={translation("results", field, lang)}
                                  tagList={
                                    docData._source[field]
                                  }
                                />
                              ),
                          )}
                        {/* Render link to original document */}
                        {!!docData._source.url && (
                          <InfoBox>
                            <i>{texts.info.machineread[lang]}</i>
                            <div className="arrow-link">
                              <ArrowRightIcon color="primary" />
                              <a
                                href={docData._source.url}
                                target="_BLANK"
                                rel="noopener noreferrer"
                              >
                                {translation("document", "avaa/lataa alkuperäinen dokumentti", lang)}
                              </a>
                            </div>
                          </InfoBox>
                        )}
                        {!!docData._source.year && parseInt(docData._source.year) < 1991 && (
                          <InfoBox>
                            <i>{texts.info.machineread_older_text[lang]}</i>
                          </InfoBox>
                        )}
                      </header>
                      {MEDIA_TYPES.includes(
                        docData._source.content_type,
                      ) ? (
                        <>
                          <embed
                            src={docData._source.url}
                            type={
                              docData._source.content_type
                            }
                            width="700"
                            height="auto"
                          ></embed>
                        </>
                      ) : docData._source.content ? (
                        <div
                          className="doc-content"
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={formatContent(
                            docData._source.content
                          )}
                        />
                      ) : (!docData._source.content && lang === "fi") ? 
                        (
                          <div className="doc-content">
                            {console.log("docData._source.content: ", docData._source.content)}
                            {renderNoContent()}
                          </div>
                        ):
                        (
                          <div>
                            {renderNoContentInSwedish()}
                          </div>
                        )
                      }
                    </main>
                  </Paper>
                )}

                {/* Show highlighted inner hits under the document (lausuntokierros -specifig) */}
                {!!sideData?.innerHits?.length && (
                  <Paper
                    elevation={2}
                    className="inner-hit-paper"
                  >
                    {sideData.innerHits.map(
                      (innerHit) =>
                        !!innerHit.highlight && (
                          <div
                            key={innerHit._source.id}
                            className="inner-hit--container"
                          >
                            <h3 className="inner-hit--title">
                              <Link
                                to={`/asiakirjat/lausuntokierros-asiakirjat/${innerHit._source.id}${location.search}`}
                              >
                                {innerHit._source.title}
                              </Link>
                            </h3>
                            <div className="inner-hit--meta">
                              <p>
                                {formatDate(
                                  innerHit._source
                                    .laatimispaiva,
                                )}
                              </p>
                              <p>
                                {translation("document", "tyyppi", lang)}:{' '}
                                {translateKeyword(
                                  innerHit._source.tyyppi, lang
                                ).toUpperCase()}
                              </p>
                              <p>
                                {translation("document", "laatija", lang)}:{' '}
                                {innerHit._source.laatija}
                              </p>
                              {innerHit._source.url ? (
                                <a
                                  href={
                                    innerHit._source &&
                                    innerHit._source.url
                                  }
                                >
                                  {translation("document", "original", lang)}
                                </a>
                              ) : (
                                <i>
                                  {translation("document", "no_original", lang)}
                                </i>
                              )}
                            </div>
                            {!!innerHit.highlight &&
                              innerHit.highlight[
                                'asiakirjat.content'
                              ].map((highlight, idx) => (
                                <div
                                  className="inner-hit--snippets"
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={idx}
                                >
                                  <span
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                      __html: `...${highlight}... `,
                                    }}
                                  />
                                </div>
                              ))}
                            {!!innerHit.error && (
                              <div>{innerHit.error}</div>
                            )}
                          </div>
                        ),
                    )}
                  </Paper>
                )}
              </Grid>
            </>
          )}

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={3}
            role="complementary"
            className={classes.reverseColumn}
          >
            {status === 'ready' && (
              <DocumentSide sideData={sideData} />
            )}
            {!!siblings.length && (
              <Siblings
                data={siblings}
                docID={docID}
                docGroup={docGroup}
                lang={lang}
              />
            )}
          </Grid>
        </Grid>
        {!isMobile &&
        <Fab
          variant="circular"
          size="medium"
          color="secondary"
          aria-label="add"
          className={
            showFab
              ? 'fab-navigation'
              : 'fab-navigation fab-hide'
          }
          onClick={() => scrollToTop()}
        >
          {/* <NavigationIcon /> */}
          <ArrowUpwardIcon />
        </Fab>
        }
      </Section>
    </>
  );
};

Document.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      doc_group: PropTypes.string,
      doc_id: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default Document;
