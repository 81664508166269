import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import Section from 'components/Section';
import { Paper } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { translation } from 'utils/translate';

const Referencing = () => {
  // scroll to top when entering page view
  const lang = useSelector((store) => store.lang);

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <Helmet>
        <title>{translation("viittaaminen", lang)}</title>
      </Helmet>
      <Section className="section--page">
        <Paper elevation={0}>
          <div className={isMobile? "page-wrapper-mobile":"page-wrapper"}>
            <h1 className="page-title">
                {translation("viittausohjeet", lang)}
            </h1>
            {lang === "fi" &&
            <>
            <p>
              Kun hyödynnät Lakitutkaa esimerkiksi
              tutkimuksessa, tulee julkaisun metodiosiossa
              mainita, että tutkimuksessa on käytetty Turun
              yliopiston Lakitutkaa. Aineistoluetteloon
              tulee sisällyttää yleinen maininta siitä, että
              Lakitutkaa on käytetty aineiston
              keräämiseen/rajaamiseen/muuhun vastaavaan.
              Jokaisen Lakitutkalla kerätyn asiakirjan
              kohdalla ei kuitenkaan tarvitse viitata
              erikseen Lakitutkaan. Viittaa Lakitutkaan
              aineistoluettelossa näin: Tutkimusaineiston
              keräämiseen/rajaamiseen/analysointiin/… on
              käytetty Turun yliopiston{' '}
              <a
                href="https://lakitutka.fi/"
                target="_BLANK"
                rel="noopener noreferrer"
              >
                Lakitutka-palvelua
              </a>
              .
            </p>{' '}
            </>
            }
            {lang === "se" &&
              <p>
                Då du använder Lagradarn, t.ex. i en studie, 
                måste du i metodavsnittet av publikationen nämna 
                att studien utnyttjat Lagradarn från Åbo universitet. 
                Listan över data bör innehålla ett allmänt omnämnande 
                om att Lagradarn använts för datainsamling/ klassificering/ etc. 
                För varje dokument som samlats in med Lagradarn behöver man 
                dock inte göra en separat hänvisning till Lagradarn. 
                Vänligen hänvisa till Lagradarn i listan över data på 
                följande sätt: Åbo universitets 
                <a
                  href="https://lakitutka.fi/"
                  target="_BLANK"
                  rel="noopener noreferrer"
                > Lagradarn-tjänst </a>har använts för att samla 
                in/ begränsa/ analysera/... forskningsdata 
              </p>
            }
            <p>
              DOI:{' '}
              <a
                href="https://zenodo.org/record/6504432"
                target="_BLANK"
                rel="noopener noreferrer"
              >
                10.5281/zenodo.6504432
              </a>
            </p>{' '}
          </div>
        </Paper>
      </Section>
    </>
  );
};

export default Referencing;
