import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Section from 'components/Section';
import SearchForm from 'components/SearchForm';
import SearchForEveryone from 'components/SearchForEveryone';
import config from 'config';
import './Home.css';
import texts from 'texts';
import { Helmet } from 'react-helmet';
// import {
//   Hidden,
//   Select,
//   MenuItem,
//   FormControl,
// } from '@material-ui/core';
import {
  scrollToTop,
} from 'utils/commonTools';
import { changeLanguage } from 'actions/langActions';
import { setSearchFormFullScreen } from 'actions/searchFormFullScreenActions';
import Fab from '@material-ui/core/Fab';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { isMobile } from 'react-device-detect';
 import { translation } from 'utils/translate';

const Home = () => {
  const { hash } = useLocation();
  const dispatch = useDispatch();

  const lang = useSelector((store) => store.lang);
  const fullScreen = useSelector((store) => store.searchFormFullScreen)

  const activeFormGlobal = useSelector((store) => store.docGroup)
  
  const searchState = useSelector((store) => store.searchState)

  const isSubmited = useSelector((store) => store.isSearchSubmited)

  // const hashLinkScroll = () => {
  //   const { hash } = window.location;
  //   if (hash !== '') {
  //     // Push onto callback queue so it runs after the DOM is updated,
  //     // this is required when navigating from a different page so that
  //     // the element is rendered on the page before trying to getElementById.
  //     setTimeout(() => {
  //       const id = hash.replace('#', '');
  //       const element = document.getElementById(id);
  //       if (element) element.scrollIntoView();
  //     }, 0);
  //   }
  // };

  useEffect(() => {
    const fab = document.getElementsByClassName(
      'fab-navigation',
    )[0];
    const myScrollFunc = () => {
      if (window.scrollY >= 800) {
        fab.classList.remove('fab-hide');
      } else {
        fab.classList.add('fab-hide');
      }
    };

    window.addEventListener('scroll', myScrollFunc);

    //hashLinkScroll();

    if(window.location.search){
      if (window.location.search.includes("lang=se")){
        dispatch(changeLanguage("se"))
      }
    }
    else{
      if(window.location.href.includes("#basic-search-term")){
        dispatch(setSearchFormFullScreen(false))
        if(isSubmited === true){
          dispatch(setSearchFormFullScreen(true))
        }
      }
    }
    if(window.location.search === "?lang=se" || window.location.search === "?lang=fi"){
      if(isSubmited){
        dispatch(setSearchFormFullScreen(true))
      }
      else{
        dispatch(setSearchFormFullScreen(false))
      }
    }
  }, [hash, fullScreen, isSubmited, dispatch]);

  return (
    <>
      <Helmet>
        <title>Etusivu | {config.site.name}</title>
      </Helmet>
      {!isMobile &&
        <Fab
          variant="circular"
          size="medium"
          color="secondary"
          aria-label="add"
          className="fab-navigation fab-hide"
          onClick={() => scrollToTop()}
        >
          {/* <NavigationIcon /> */}
          <ArrowUpwardIcon />
        </Fab>
      }
      {searchState === "yleinen" &&
       <>
          <div className='section-for-everyone'>
            <SearchForEveryone/>
          </div>
        </>
      }
      {searchState === "tutkija" &&
        <>
        <Section className="section--info-area">
          {fullScreen === false &&
            <div className="intro-info">
              <h1>{texts.info.intro_header[lang]}</h1>
              {texts.info.intro[lang]}
            </div>
          }
          {fullScreen &&
            <div className="search-result-title">
              <h1>{translation("Hakutulokset", lang)}</h1>
            </div>
          }  
        </Section>

        {/* Mobile selector */}
        {/* <Hidden smUp>
          <Section
            className="section--select-area section--select-area--mobile"
            role="navigation"
          >
            <h3
              className="document-type-mobile-select-label"
              id="mobiselect-label"
            >
              {translation("mobile_document_group", lang)}
            </h3>
            <FormControl
              variant="filled"
              fullWidth
              className="document-type-mobile-select"
            >
              <Select
                labelId="mobiselect-label"
                fullWidth
                value={activeFormGlobal}
                onChange={(event) =>
                  changeDocGroupState(event.target.value)
                }
              >
                {Object.entries(config.docGroups).map(
                  ([key, value]) =>
                    value.searchable && (
                      <MenuItem value={key} key={key}>
                        {translation("doc_groups", key, "name", lang)}
                      </MenuItem>
                    ),
                )}
              </Select>
            </FormControl>
          </Section>
        </Hidden> */}

        <Section className="section--content" role="main"> 
          <SearchForm docGroup={activeFormGlobal} />

        </Section>
        </>
      }
    </>
  );
};

export default Home;
