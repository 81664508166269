// sort array alphabetically by key value
export const sortByKey = (facets, key, direction) => {
  const dir = direction === 'desc' ? -1 : 1;
  return [...facets].sort((a, b) => {
    if (isNaN(a[key]) && isNaN(b[key])) {
      return (
        dir *
        a[key].localeCompare(b[key], undefined, {
          sensitivity: 'base',
        })
      );
    } else {
      return a[key] > b[key]
        ? dir
        : a[key] < b[key]
          ? -1 * dir
          : 0;
    }
  });
};

// transform an array of keys into object with empty arrays with those keys
export const initializeFilters = (keys) =>
  keys.reduce(
    (soFar, item) => ({
      ...soFar,
      [item]: [],
    }),
    {},
  );

export const initializeFiltersWithDefaultValue = (
  keys,
  value,
) =>
  keys.reduce(
    (soFar, item) => ({
      ...soFar,
      [item]: value,
    }),
    {},
  );

// capitalize first letter of a string
export const capitalizeFirstLetter = (word) =>
  word?.length > 1
    ? word.charAt(0).toUpperCase() + word.slice(1)
    : word;

// Cuts and ellipsises (…) input string if it is
// longer than threshold value
export const cropIfLonger = (input, threshold = 100) =>
  input?.length > threshold
    ? `${input.slice(0, threshold - 2)}…`
    : input;

// Check if input is most propably timestamp ie. "2021-03-04"
export const isTimestamp = (input) =>
  /\d{4}-\d{2}-\d{2}/.test(input);

// Format timestamp eg. "2021-03-04" => "4.3.2021"
export const formatDate = (input) => {
  if (!input || !isTimestamp(input)) {
    return 'Ei päiväystä';
  }
  const date = new Date(input);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

export const cleanTitle = (input) =>
  capitalizeFirstLetter(
    input
      ?.replace(
        // Remove "HE 101/2019 vp HaV 06.02.2020 "-type prefix from asiantuntijalausunnot
        /^HE \d+\/\d{4} vp [a-z]+ \d+\.\d+\.\d{4} /i,
        '',
      )
      // and ' Asiantuntijalausunto' postfix
      .replace(/ Asiantuntijalausunto$/, ''),
  );



// Get url to document ie. "/asiakirjat/lausuntokierros/132230"
export const getDocumentUrl = (docType, id) => {
  if (docType === "lausuntokierros_muut" || docType === "lausuntokierros_lausunnot" || Number.isInteger(id)) {
    return `/asiakirjat/lausuntokierros-asiakirjat/${id}`;
  }
  return `/asiakirjat/${docType.replace('_', '-')}/${id}`;
}

// Get url to law in Finlex ie. "https://finlex.fi/fi/laki/alkup/2019/20190345"
export const getLawUrl = (input, lang = "fi") => {
  if (!input) {
    return null;
  }
  const label = input.split('/');
  console.log(lang)
  if (lang === "se")
  {
    return `https://finlex.fi/sv/laki/alkup/${label[1]}/${label[1]
    }${`000${label[0]}`.substr(-4)}`;
  }
  else {
    return `https://finlex.fi/fi/laki/alkup/${label[1]}/${label[1]
    }${`000${label[0]}`.substr(-4)}`;
  }
};

export const getLausuntopalveluUrl = (id) => {
  return `https://www.lausuntopalvelu.fi/FI/Proposal/Participation?proposalId=${id}`;
};

export const isProductionSite =
  window.location.href.indexOf('https://lakitutka.fi') ===
  0;

// For filtering items from lists according to environment
export const showInCurrentEnvironment = (item) =>
  !isProductionSite || !item.hideFromProduction;

export const randomId = (length) => {
  const result = [];
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i += 1) {
    result.push(
      characters.charAt(
        Math.floor(Math.random() * characters.length),
      ),
    );
  }
  return result.join('');
};

export const mapIndiceToDocGroup = (index) => {
  return {
    laki_lausuntokierros: 'lausuntokierros',
    laki_lausuntokierros_asiakirjat: 'lausuntokierros-asiakirjat',
    laki_la: 'lakialoite',
    laki_ka: 'kansalaisaloite',
    laki_he: 'hallituksen-esitykset',
    laki_vk: 'valiokunta-asiakirjat',
    laki_puheenvuoro: 'poytakirja',
  }[index]; // if index not found from the map use default 'all'
};

export const tunnus2DocId = (tunnus) =>
  tunnus.toLowerCase().replace(' ', '').replace('/', '-');

export const tunnusWithVp2DocId = (tunnus_with_vp) =>
  tunnus_with_vp.replace(' vp', '').toLowerCase().replace(' ', '').replace('/', '-');

export const scrollToTop = () => {
  document.documentElement.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};
