import React, { useState, useEffect } from 'react';
import { 
    Grid, 
    Box,
    makeStyles,
    Chip,
    Button,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { Link, useParams} from "react-router-dom";
import config from 'config';
import SearchForm from 'components/SearchForm';
import { useSelector } from 'react-redux';
import apiService from 'services/apiService';
import { reportError } from 'actions/errorActions';
import { translation } from 'utils/translate';


const useStyles = makeStyles((theme) => ({
    page:{
        // marginLeft: 'auto',
        // marginRight: 'auto'
    },
    boxes: {
    },
    boxesGrid: {
    },
    linksTitle: {
        fontWeight: "semiBold",
        marginLeft: 0,
        marginTop: 0
    },
    linksBoxPink: {
        backgroundColor: "#ffcae9",
        marginBottom: 20,
        paddingBottom: "2rem",
        paddingRight: "2rem",
        fontSize: 14
    },
    linksBoxGreen: {
        backgroundColor: "#ACE7CA",
        marginBottom: 20,
        paddingBottom: "2rem",
        paddingRight: "2rem",
        fontSize: 14
    },
    linksBoxYellow: {
        backgroundColor: "#FFED65",
        marginBottom: 20,
        paddingBottom: "2rem",
        paddingRight: "2rem",
        fontSize: 14
    },
    boxTitle: {
        fontWeight: "Bold",
    },
    boxTitleDiv:{
        flexDirection: "row",
        marginLeft: "5%",
        marginTop: "5%",
    },
    titleDiv:{
        flexDirection: "column",
    },
    boxLogoDiv:{
        flexDirection: "row",
        marginLeft: "5%",
        marginTop: "5%", 
    },
    boxItems: {
        display: "flex",
    },
    quickLinkBox: {
        display: "flex",
    },
    arrow:{
        flexDirection: "row",
    },
    link:{
        flexDirection: "row",
        color: "black",
        fontWeight: "bold",
        textDecoration: "underline",
        textDecorationThickness: 1,
        marginLeft: 10,
        marginTop: 5
    },
    button:{
        flexDirection: "row",
        marginLeft: 2,
        textTransform: 'none',
        color: "black",
        lineHeight: '18px',
        fontWeight: "bold",
        textDecoration: "underline",
        textDecorationThickness: 1,
        height: 28,
        textAlign: 'left',
        '&:hover': {
            backgroundColor: 'unset',
            borderColor: 'unset',
            boxShadow: 'unset',
            textDecoration: "underline",
            textDecorationThickness: 1,
            color: 'hsl(202, 67%, 28%)',
          },
    },
    whale:{
        justifyContent: "center",
        display: "flex",
        marginTop: 0,
        paddingBottom: 0.5
    },
    text:{
        justifyContent: "center",
        display: "flex",
        marginBottom: "1rem"
    },
    lakitutka:{
        marginBottom: -10 
    },
    searchGrid:{
    }, 
    searchDiv: {
        display: 'block',
        flexWrap: 'wrap',
        justifyContent: 'left',
    },
    searchDivFullScreen: {
    },
    subjectsDiv: {
        marginRight: "1rem",
        marginTop: "1rem",
    },
    blackChip: {
        color: "#070707",
        backgroundColor: "#f4f5f5",
        border: "2px solid #070707",
        padding: "3px",
        fontWeight: "bold",
        margin: "3px",
    }
}))

const SearchForEveryone = () => {
    const classes = useStyles();
    const { docGroup } = useParams();
    const activeForm = docGroup || config.defaultDocGroup
    const fullScreen = useSelector((store) => store.searchFormFullScreen)
    const lang = useSelector((store) => store.lang)
    const [chips, setChips] = useState([])

    //Tarkistele aihealueittain keywords:
    useEffect(() =>{
        async function fetchData() {
            let keywords = null
            try{
                keywords = await apiService.getKeywords(lang)
            }catch (error){
                reportError(error)
                console.log(error)
                return
            }
            if(keywords?.response){
                setChips(keywords.response)
            }
        }
        fetchData();
    },[lang])

    const handleChipClick = (chip) => {
        if (chip){
            const link = '/haku/hallituksen_esitykset?term='+chip[0]+'&sort=score&lang='+lang
            window.location.href=link
        }
    }
    //Pikalinkit:
    //Uusimmat:
    const handleLatestOnClick = async () => {
        let result = null
        try{
            result = await apiService.getLatests()
        }catch (error) {
            reportError(error)
            console.log(error)
            return
        }
        if (result?.response) {
            let resultString = result.response.toString()
            resultString= resultString.replaceAll(":", "%3A")
            resultString= resultString.replaceAll("/","%2F")
            resultString=resultString.replaceAll(",","%2C")
            const link = '/haku/lausuntokierros?sort=alpha_desc&prefilters={"tunnus"%3A"'+resultString+'"}&lang='+lang
            window.location.href=link
        }
    }
    //Eduskunnassa:
    const handleEduskunnassa = async () => {
        let result = null
        try{
            result = await apiService.getLatestsHallituksenEsitykset()   
        }catch (error) {
            reportError(error)
            console.log(error)
            return
        }
        if (result?.response) {
            let resultString = result.response.toString()
            resultString= resultString.replaceAll("/","%2F") 
            resultString=resultString.replaceAll(",","%2C")
            const link = '/haku/lausuntokierros?sort=alpha_desc&prefilters={"tunnus"%3A"'+resultString+'"}&lang='+lang
            window.location.href=link
        }
    }
    //Osallistu:
    const handleOngoing = async () => {
        let result = null
        try{
            result = await apiService.getOngoing()    
        }catch (error) {
            reportError(error)
            console.log(error)
            return
        }
        let resultArray=[]
        if (result?.response) {
            result.response.map((result) => resultArray.push(result[0]))
            let resultString = resultArray.toString()
            resultString = resultString.replaceAll(":", "%3A")  
            resultString = resultString.replaceAll("/","%2F")
            const link = '/haku/all?sort=alpha_desc&prefilters={"tunnus"%3A"'+resultString+'"}&lang='+lang
            window.location.href=link
        }
    }
    //Oikopolut hakuun:
    //Uusimmat hallituksen esitykset:
    const handleLatestGovernmentPresentations = () =>{
        const link = 'haku/hallituksen_esitykset?&sort=date_desc&lang='+lang
        window.location.href=link
    }
    //Talousarviot:
    const handleBudgets = () =>{
        const link = 'haku/hallituksen_esitykset?sort=date_desc&prefilters=%7B%22talousarviot%22%3A%22strictly%22%7D&lang='+lang
        window.location.href=link
    }
    //Eniten eduskunnassa keskustellut:
    const handleStatements = async () => {
        let result = null
        try{
            result = await apiService.getStatements()    
        }catch (error) {
            reportError(error)
            console.log(error)
            return
        }
        let resultArray=[]
        if (result?.response) {
            result.response.map((result) => resultArray.push(result[0]))
            resultArray = resultArray.reverse()
            let resultString = resultArray.toString()
            resultString = resultString.replaceAll("/","%2F")
            const link = '/haku/all?sort=alpha_desc&prefilters={"tunnus"%3A"'+resultString+'"}&lang='+lang
            window.location.href=link
        }
    }

    return(
        <Grid container spacing={5} className={classes.page}>
            <Grid item  xs={12} sm={12} md={fullScreen? 12 : 8 } lg={fullScreen? 12 : 8} className={classes.searchGrid}>
                {fullScreen &&
                    <div>
                        <h1>{translation("Hakutulokset", lang)}</h1>
                    </div>
                }
                {fullScreen === false &&
                    <>
                        <div className = {classes.whale}>
                            <img 
                                alt="Lakita"
                                src="/lakita.svg"
                                width="225"
                            />
                        </div>
                        <div className={classes.whale}>
                            <h1 className={classes.lakitutka}>{translation("site_name", lang)}</h1>
                        </div>
                        <div className={classes.text}>
                            <p>{translation("Lakitutka_intro_for_everyone", lang)}</p>
                        </div>
                    </>
                }
                <div className={fullScreen? classes.searchDivFullScreen : classes.searchDiv}>
                    <SearchForm docGroup={activeForm}/>
                </div> 
                {!fullScreen &&
                <div className={classes.subjectsDiv}>
                    <h2 className = {classes.linksTitle}>{translation("tarkastele_aihealueittain", lang)}</h2>
                    {chips.map((chip,key)=>
                        <Chip
                            key={key}
                            label={chip[0]}
                            className={classes.blackChip}
                            clickable
                            variant="outlined"
                            onClick={()=> handleChipClick(chip)}
                            
                        />)
                    }
                </div>
                }
            </Grid>
            {!fullScreen &&
            <Grid item  xs={12} sm={12} md={4} lg={4} className={classes.boxes}>
                <Grid>
                    <div>
                        <h2 className = {classes.linksTitle}>{translation("pikalinkit", lang)}</h2>
                    </div>
                    <Grid className={classes.boxesGrid}>
                        <div>
                            <Box sx={{ flexGrow: 1, overflow: 'hidden'}} className={classes.linksBoxPink}>
                                <div className={classes.boxItems}>
                                    <div className={classes.boxLogoDiv}>
                                        <img
                                            alt="logo"
                                            src="/hankkeet_icon.svg"
                                        />
                                    </div>
                                    <div className={classes.boxTitleDiv}>
                                        <div className={classes.titleDiv}>
                                            <h3 className={classes.boxTitle}>{translation("käynnissä_olevat_hankkeet", lang)}</h3>
                                        </div>
                                   
                                        <div className={classes.quickLinkBox}>
                                            <img
                                                alt="arrow"
                                                src="/hero_arrow_right.svg"
                                                className={classes.arrow}
                                            ></img>
                                            <Button
                                                className={classes.button}
                                                onClick={handleLatestOnClick}
                                            >
                                                {translation("uusimmat",lang)}
                                            </Button>
                                        </div>
                                        <div className={classes.quickLinkBox}> 
                                            <img
                                                alt="arrow"
                                                src="/hero_arrow_right.svg"
                                                className={classes.arrow}
                                            ></img>
                                            <Button
                                                className={classes.button}
                                                onClick={handleEduskunnassa}
                                                >{translation("eduskunnassa",lang)}
                                            </Button> 
                                        </div>
                                        <div className={classes.quickLinkBox}> 
                                            <img
                                                alt="arrow"
                                                src="/hero_arrow_right.svg"
                                                className={classes.arrow}
                                            ></img>
                                            <Button 
                                                className={classes.button}
                                                onClick={handleOngoing}
                                                >{translation("osallistu", lang)}
                                            </Button> 
                                        </div>
                                    </div>
                                </div>
                            </Box>       
                        </div> 
                    </Grid>
                        <Grid>
                            <div>
                                <Box sx={{ flexGrow: 1, overflow: 'hidden'}} className={classes.linksBoxGreen}>
                                    <div className={classes.boxItems}>
                                        <div className={classes.boxLogoDiv}>
                                            <img
                                                alt="logo"
                                                src="/oikopolut_icon.svg"
                                        />
                                        </div>
                                        <div className={classes.boxTitleDiv}>
                                            <div className={classes.titleDiv}>
                                                <h3 className={classes.boxTitle}>{translation("oikopolut_hakuun",lang)}</h3>
                                            </div>
                                            <div className={classes.quickLinkBox}>
                                                <img
                                                    alt="arrow"
                                                    src="/hero_arrow_right.svg"
                                                    className={classes.arrow}
                                                ></img>
                                                <Button 
                                                    className={classes.button}
                                                    onClick={handleLatestGovernmentPresentations}
                                                    >{translation("uusimmat_he", lang)}
                                                </Button>  
                                            </div>
                                            <div className={classes.quickLinkBox}> 
                                                <img
                                                    alt="arrow"
                                                    src="/hero_arrow_right.svg"
                                                    className={classes.arrow}
                                                ></img>
                                                <Button 
                                                    className={classes.button}
                                                    onClick={handleBudgets}
                                                    >{translation("talousarviot", lang)}
                                                </Button> 
                                            </div>
                                            <div className={classes.quickLinkBox}> 
                                                <img
                                                    alt="arrow"
                                                    src="/hero_arrow_right.svg"
                                                    className={classes.arrow}
                                                ></img>
                                                <Button 
                                                    className={classes.button}
                                                    onClick={handleStatements}
                                                    >{translation("eniten_eduskunnassa_keskustellut", lang)}
                                                </Button> 
                                            </div>
                                            {/* <div className={classes.quickLinkBox}> 
                                                <img
                                                    alt="arrow"
                                                    src="/hero_arrow_right.svg"
                                                    className={classes.arrow}
                                                ></img>
                                                <Link className={classes.link}
                                                    to=""
                                                    >Valiokuntien käsittelyssä olevat
                                                </Link> 
                                            </div> */}
                                        </div>
                                    </div>
                                </Box>
                            </div> 
                        </Grid>
                    <Grid>
                        <div>
                            <Box sx={{ flexGrow: 1, overflow: 'hidden'}} className={classes.linksBoxYellow}>
                                <div className={classes.boxItems}>
                                    <div className={classes.boxLogoDiv}>
                                        <img
                                            alt="logo"
                                            src="/opi_icon.svg"
                                        />
                                    </div>
                                    <div className={classes.boxTitleDiv}>
                                        <div className={classes.titleDiv}>
                                            <h3 className={classes.boxTitle}>{translation("opi_miten_lait_syntyvät", lang)}</h3>
                                        </div>
                                        <div className={classes.quickLinkBox}> 
                                            <img
                                                alt="arrow"
                                                src="/hero_arrow_right.svg"
                                                className={classes.arrow}
                                            ></img>
                                            <Link className={classes.link}
                                                to={{ pathname: "https://www.youtube.com/watch?v=KdIMfFCV2Mw" }}
                                                target='_blank'
                                                >{translation("video_näin_lait_synytvät", lang)}
                                            </Link> 
                                        </div>
                                        {(lang === 'se') ?
                                            <div className={classes.quickLinkBox}> 
                                                <img
                                                    alt="arrow"
                                                    src="/hero_arrow_right.svg"
                                                    className={classes.arrow}
                                                ></img>
                                                <Link className={classes.link}
                                                     to={{ pathname: "https://www.youtube.com/watch?v=2jU6QArc5LY" }}
                                                     target='_blank'
                                                    >{translation("video_näin_lait_syntyvät_en", lang)}
                                                </Link> 
                                            </div>
                                            :<></>
                                        }
                                        <div className={classes.quickLinkBox}> 
                                            <img
                                                alt="arrow"
                                                src="/hero_arrow_right.svg"
                                                className={classes.arrow}
                                            ></img>
                                            <Link className={classes.link}
                                                 to={{ pathname: "https://www.youtube.com/watch?v=5oFocGzFHhs" }}
                                                 target='_blank'
                                                >{translation("video_kuuleminen_lainvalmistelussa", lang)}
                                            </Link> 
                                        </div>
                                        {(lang === 'se') ?
                                            <div className={classes.quickLinkBox}> 
                                                <img
                                                    alt="arrow"
                                                    src="/hero_arrow_right.svg"
                                                    className={classes.arrow}
                                                ></img>
                                                <Link className={classes.link}
                                                     to={{ pathname: "https://www.youtube.com/watch?v=CrtleyvQcjw" }}
                                                     target='_blank'
                                                    >{translation("video_kuuleminen_lainvalmistelussa_en", lang)}
                                                </Link> 
                                            </div>
                                            :<></>
                                        }
                                        <div className={classes.quickLinkBox}> 
                                            <img
                                                alt="arrow"
                                                src="/hero_arrow_right.svg"
                                                className={classes.arrow}
                                            ></img>
                                            <Link className={classes.link}
                                                to={{ pathname: "https://digicampus.fi/course/view.php?id=4775" }}
                                                target='_blank'
                                                >{translation("mooc", lang)}
                                            </Link> 
                                        </div>
                                        <div className={classes.quickLinkBox}> 
                                            <img
                                                alt="arrow"
                                                src="/hero_arrow_right.svg"
                                                className={classes.arrow}
                                            ></img>
                                            <Link className={classes.link}
                                                to="/dictionary"
                                                >{translation("lainvalmistelun_sanakirja", lang)}
                                            </Link> 
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </div> 
                    </Grid>
                </Grid>
            </Grid>
            }
        </Grid>
    )
}
export default withRouter(SearchForEveryone)