import React from 'react';
import PropTypes from 'prop-types';
import {
  ListBlock,
  StaticListBlock,
} from 'components/ListBlock';
import './DocumentSide.css';
import { translation, translateKeyword } from 'utils/translate';
import { useSelector } from 'react-redux';

/* COMPONENT FOR DOCUMENT SIDE FIELDS */

const DocumentSide = ({ sideData }) => {

  const lang = useSelector((store) => store.lang)

  return (<>
    {sideData.meta && (
      <StaticListBlock
        title="Hanke"
        data={[
          {
            key: 'state',
            value: translateKeyword(sideData.meta.project_status.split("(")[0], lang),
          },
          {
            key: 'started',
            value: sideData.meta.project_lk_julkaisu,
          },
          {
            key: 'he_laadittu',
            value: sideData.meta.project_he_laadittu,
          },
          {
            key: 'la_laadittu',
            value: sideData.meta.project_la_laadittu,
          },
          {
            key: 'sent',
            value: sideData.meta.project_ka_laadittu,
          },
          {
            key: 'valmistunut',
            value: sideData.meta.project_finished,
          },
          {
            key: 'duration',
            value: sideData.meta.project_duration && parseInt(sideData.meta.project_duration) > 0 ? `${sideData.meta.project_duration.split(" ")[0]} ${translateKeyword(sideData.meta.project_duration.split(" ")[1], lang)} (${translateKeyword("ka", lang)} ${sideData.meta.avg_duration})` : null,
          },
          {
            key: 'changes',
            value: sideData.meta.laws_count,
          },
          {
            key: 'allekirjoittaja',
            value: sideData.meta.allekirjoittaja,
          },
          {
            key: 'allekirjoittajat',
            value: sideData.meta.allekirjoittaja_lkm,
          },
          {
            key: 'kansalaisaloite_url',
            value: sideData.meta.kansalaisaloite_fi_id
              ? `<a target="_blank" rel="noreferrer noopener" href="https://www.kansalaisaloite.fi/fi/aloite/${sideData.meta.kansalaisaloite_fi_id}">Linkki</a>`
              : null,
          },
        ]}
      />
    )}
    {!!sideData.termCounts.length && (
      <ListBlock
        title={translation("document", "search_hits", lang)}
        data={sideData.termCounts}
        //anchored
        nth="first"
      />
    )}
    {!!sideData.wc.length && (
      <ListBlock
        title="Yleisimmät sanat"
        data={sideData.wc}
      />
    )}
  </>
  )
}

DocumentSide.propTypes = {
  sideData: PropTypes.shape({
    innerHits: PropTypes.arrayOf(PropTypes.shape({})),
    termCounts: PropTypes.arrayOf(PropTypes.shape({})),
    wc: PropTypes.arrayOf(PropTypes.shape({})),
    meta: PropTypes.shape({}),
  }).isRequired,
};

export default DocumentSide;
