import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import Block from 'components/Block';
import ShowMore from 'components/ShowMore';
import './ListBlock.css';
import Tip from 'components/Tip';
import { useSelector } from 'react-redux';
import { translation } from 'utils/translate';
import { isMobile } from 'react-device-detect';

/* LIST BLOCK COMPONENT */

const StaticListBlock = ({ data }) => {
  const topRef = useRef();

  const [isOpen, setIsOpen] = useState(false);

  const lang = useSelector((store) => store.lang)

  // scroll to top of the facet block
  const scrollToTop = () => {
    window.scrollTo({
      top: topRef.current.offsetTop - 20,
      behavior: 'smooth',
    });
  };

  // toggle 'show more/less'
  const toggleOpen = () => {
    setIsOpen(!isOpen);
    scrollToTop();
  };

  const renderList = isOpen
    ? [...data].filter((obj) => obj.value)
    : [...data].filter((obj) => obj.value).slice(0, 10);

  return (
    <Paper elevation={0}>
      <Block
        className={isMobile?"listblock--static-mobile":"block--facet listblock--static"}
        ref={topRef}
      >
        <Tip title={translation("document", "hankkeen_tiedot_tip", lang)}>
          <div className="facet-header-wrapper">
            <button className="facet-header facet-header--primary">
              <h4>{translation("hanke", lang).toUpperCase()}</h4>
            </button>
          </div>
        </Tip>

        <div className="listblock--items">
          {renderList.map(({ key, value }) => (
            <div
              key={key}
              className="listblock--item-container"
            >
              <div className="listblock--item-key">
                {translation("document", key, lang)}:
              </div>
              <div
                className="listblock--item-value"
                dangerouslySetInnerHTML={{ __html: value }}
              />
            </div>
          ))}
        </div>

        {data.length > 12 && (
          <ShowMore
            isOpen={isOpen}
            toggleFunction={toggleOpen}
            moreLabel={`${translation("facets", "show_all", lang)} (${data.length})`}
            lessLabel={translation("facets", "show_less", lang)}
          />
        )}

        <div className="listblock--info">
          <i>
            {translation("document", "feedback", lang)}
          </i>{' '}
          <Link
            to={{
              pathname: '/palaute',
              state: {
                from: `${window.location}`,
              },
            }}
          >
            {translation("document", "feedback_link", lang)}
          </Link>
          .
        </div>
      </Block>
    </Paper>
  );
};

StaticListBlock.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string.isRequired,
};

export default StaticListBlock;
